<template>
  <div>
    <b-card no-body>
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <H3>Şirketler</H3>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <b-row>
          <b-col md="3">Şirket Bilgileri</b-col>
          <b-col md="3">Vergi Bilgileri</b-col>
          <b-col md="3">E Arşiv Portal</b-col>
          <b-col md="3">
            <b-button size="sm" variant="primary" @click="getCompanies">Getir</b-button>
            <b-button variant="success" size="sm" @click="addCompany">Yeni Ekle</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <Company v-for="company in _companies" :key="company.id" :company="company"></Company>
    <EditCompany></EditCompany>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Company from '@/views/company/Company.vue'
import EditCompany from '@/views/company/EditCompany.vue'

export default {
  name: 'CompanyHome',
  components: {
    Company,
    EditCompany,
  },
  computed: {
    ...mapGetters('company', ['_companies']),
  },
  methods: {
    getCompanies() {
      this.$store.dispatch('company/getCompanies')
    },
    addCompany() {
      this.$root.$emit('bv::show::modal::data', { e_archive: {} })
      this.$root.$emit('bv::show::modal', 'edit-company-modal', '#btnShow')
    },
  },
  mounted() {
    this.$store.dispatch('company/getCompanies')
  },
}
</script>

<style scoped>

</style>
