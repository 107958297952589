<template>
  <b-card no-body>
    <b-card-body class="bv-example-row bv-example-row-flex-cols">
      <b-row>
        <b-col md="3">
          {{ company.name }} <br>
          {{ company.compnay }} <br>
          {{ company.company_uuid }}
        </b-col>
        <b-col md="3">
          {{ company.tax_number }} <br>
          {{ company.tax_office }} <br>
        </b-col>

        <b-col md="3" v-if="company.e_archive">
          {{ company.e_archive.code }} <br>
          ******
        </b-col>

        <b-col md="3" v-else>
        </b-col>
        <b-col md="3">
          <b-button size="sm" variant="primary" @click="editCompany">Düzelt</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Company',
  props: {
    company: {
      required: true,
      type: Object,
    },
  },
  methods: {
    editCompany() {
      this.$root.$emit('bv::show::modal::data', this.company)
      this.$root.$emit('bv::show::modal', 'edit-company-modal', '#btnShow')
    },
  },
}
</script>

<style scoped>

</style>
