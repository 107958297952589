<template>
  <b-modal id="edit-company-modal" hide-footer>
    <template #modal-title>
      <H1 v-if="company.company_uuid">Şirket Bilgileri Düzenleme</H1>
      <H1 v-else>Yeni Şirket Ekleme</H1>
    </template>
    <div class="d-block text-center">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Şirket Kodu" label-cols-md="4">
            <b-input v-model="company.company_uuid" placeholder="Şirket Kodu" disabled/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Şirket Kısa Adı" label-cols-md="4">
            <b-input v-model="company.name" placeholder="Şirket Adı"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Şirket Ünvanı" label-cols-md="4">
            <b-form-input v-model="company.compnay" type="text" placeholder="Şirket Ünvanı"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Vargi Dairesi" label-cols-md="4">
            <b-form-input v-model="company.tax_office" type="text" placeholder="Vargi Daires"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Vargi Numarası" label-cols-md="4">
            <b-form-input v-model="company.tax_number" type="text" placeholder="Vargi Numarası"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="company.e_archive">
          <b-form-group label="E Arşiv Kullanıcı Kodu" label-cols-md="4">
            <b-form-input v-model="company.e_archive.code" type="text" placeholder="Kullanıcı Kodu"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="E Arşiv Şifre" label-cols-md="4">
            <b-form-input v-model="company.e_archive.password" type="text" placeholder="Şifre"/>
            <b-form-text>Bu bilgi sunucuda şifrelenmiş olarak gizli tutulacaktır.</b-form-text>
          </b-form-group>
        </b-col>

        <b-col offset-md="4">
          <b-button v-if="company.company_uuid" @click="updateCompany" variant="primary" class="mr-1">
            Güncelle
          </b-button>
          <b-button v-else @click="saveCompany" variant="primary" class="mr-1">
            Kaydet
          </b-button>
        </b-col>

      </b-row>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/libs/axios'

export default {
  name: 'EditCompany',
  data: () => ({
    company: {
      company_uuid: '',
      e_archive: {},
    },
  }),
  methods: {
    updateCompany() {
      axios.put('company/' + this.company.company_uuid, this.company)
        .then(res => {
          this.company = res.data.content
        })
        .catch(() => {
          alert('Tüm Verileri Eksiksiz Doldurunuz!')
        })
    },
    saveCompany() {
      axios.post('company', this.company)
        .then(res => {
          if (res.data.status === 'success') {
            this.company = res.data.content
            this.$store.commit('company/addCompany', res.data.content)
          } else {
            alert('Bir sorun var gibi')
          }
        })
        .catch(() => {
          alert('Tüm Verileri Eksiksiz Doldurunuz!')
        })
    },
  },
  mounted() {
    this.$root.$on('bv::show::modal::data', company => {
      this.company = company
    })
  },
}
</script>

<style scoped>

</style>
